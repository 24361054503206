import React from 'react'
import { Routes, Route } from 'react-router-dom'
import About from './components/About/About';
// import Blog from './components/Blog/Blog'
import Home from './components/Home/Home'
import Contact from './components/Contact/Contact';
import Exifpro from './components/products/exifpro/Exifpro'
import Contactsbackup from './components/products/Contactsbackup/Contactsbackup'
import Vcardviewer from './components/products/Vcardviewer/Vcardviewer';
import CadToPdfConverter from './components/products/CadToPdfConverter/CadToPdfConverter';


function App() {
  return (
    <div>

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/products/exifpro' element={<Exifpro />} />
        <Route path='/products/contactsbackup' element={<Contactsbackup />} />
        <Route path='/products/vcardviewer' element={<Vcardviewer />} />
        <Route path='/products/cadToPdfConverter' element={<CadToPdfConverter />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<About />} />
        {/* <Route path='/blog' element={<Blog />} /> */}
      </Routes>

    </div>
  );
}

export default App;
